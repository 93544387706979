import Flux from '../flux';
import Actions from '../actions/adminActions.js';
export default Flux.createStore({
	displayName: 'AdminStore',
	bindListeners: {
		handleGetPriorityList: Actions.GET_PRIORITY_LIST,
		handleGetCorsConfigUrl: Actions.GET_CORS_CONFIG_URL,
		handleGetIpAddressBlacklist: Actions.GET_IP_ADDRESS_BLACKLIST,
		handleGetSalutations: Actions.GET_SALUTATIONS,
		handleGetSalutationById: Actions.GET_SALUTATION_BY_ID,
		handleGetSignatures: Actions.GET_SIGNATURES,
		handleGetSignatureById: Actions.GET_SIGNATURE_BY_ID,
		handleGetTemplates: Actions.GET_TEMPLATES,
		handleGetTemplateById: Actions.GET_TEMPLATE_BY_ID,
		handleGetFileArchives: Actions.GET_FILE_ARCHIVES,
		handleGetFileArchiveById: Actions.GET_FILE_ARCHIVE_BY_ID,
		handleGetAddressbook: Actions.GET_ADDRESSBOOK,
		handleGetAddressbookById: Actions.GET_ADDRESSBOOK_BY_ID,
		handleGetWordlist: Actions.GET_WORDLIST,
		handleGetWordlistById: Actions.GET_WORDLIST_BY_ID,
		handleGetContactCardList: Actions.GET_CONTACTS,
		handleGetContactById: Actions.GET_CONTACT_BY_ID,
		handleGetCompanyList: Actions.GET_COMPANY_LIST,
		handleGetCustomerNotesById: Actions.GET_CUSTOMER_NOTES_BY_ID, //lwb wip
		handleGetExternalExpert: Actions.GET_EXTERNAL_EXPERT,
		handleSetFileUpload: Actions.SET_UPLOADED_FILE,
		handleSetUploadedAvatar: Actions.SET_UPLOADED_AVATAR,
		handleGetTagList: Actions.GET_TAG_LIST,
		handleGetGroups: Actions.GET_GROUPS,
		handleGetGroupById: Actions.GET_GROUP_BY_ID,
		handleGetFolders: Actions.GET_FOLDERS,
		handleGetFolderById: Actions.GET_FOLDER_BY_ID,
		handleGetConnectionById: Actions.GET_CONNECTION_BY_ID,
		handleGetSmppConfigs: Actions.GET_SMPP_CONFIGS,
		handleGetSmppConfigById: Actions.GET_SMPP_CONFIG_BY_ID,
		handleGetEmailNotifications: Actions.GET_EMAIL_NOTIFICATIONS,
		handleGetRoutingGroups: Actions.GET_ROUTINGGROUPS,
		handleGetRoutingGroupById: Actions.GET_ROUTINGGROUP_BY_ID,
		handleGetSolidusChannelsList: Actions.GET_SOLIDUS_CHANNELS_LIST,
		handleGetSolidusChannelById: Actions.GET_SOLIDUS_CHANNEL_BY_ID,
		handleGetOrganisations: Actions.GET_ORGANISATIONS_LIST,
		handleGetAreaThemes: Actions.GET_AREA_THEMES,
		handleGetAreaThemeById: Actions.GET_AREA_THEME_BY_ID,
		handleGetAreaSmppAccounts: Actions.GET_AREA_SMPP_ACCOUNTS,
		handleGetAreaSmppAccountById: Actions.GET_AREA_SMPP_ACCOUNT_BY_ID,
		handleGetAreaKeywords: Actions.GET_AREA_KEYWORDS,
		handleGetAreaAutoTagsRules: Actions.GET_AREA_AUTO_TAG_RULES,
		handleGetAreaExtFwdAddr: Actions.GET_AREA_EXT_FWD_ADDR,
		handleGetAreaReplacement: Actions.GET_AREA_REPLACEMENT,
		handleGetAreaAutoFwdExtAddr: Actions.GET_AREA_AUTO_FWD_EXT_ADDR,
		handleGetTickerFilterList: Actions.GET_TICKER_FILTERS,
		handleGetSystemGroups: Actions.GET_SYSTEM_GROUPS,
		handleGetSystemGroupById: Actions.GET_SYSTEM_GROUP_BY_ID,
		handleGetSwitchSystemGroups: Actions.GET_SWITCH_SYSTEMGROUPS,
		handleGetFeatures: Actions.GET_FEATURES,
		handleGetFeatureApplied: Actions.GET_FEATURE_APPLIED,
		handleGetOrganisationById: Actions.GET_ORGANISATION_BY_ID,
		handleGetAreaById: Actions.GET_AREA_BY_ID,
		handleGetQuickSearh: Actions.GET_QUICK_SEARCH,
		handleSetCopyData: Actions.SET_COPY_DATA,
	},
	state: {
		prioritySrc: {},
		corsURLList: [],
		ipAddressBlacklist: [],
		salutationList: [],
		salutationSrc: {},
		signatureList: [],
		signatureSrc: {},
		templateList: [],
		templateSrc: {},
		fileArchiveList: [],
		fileArchiveSrc: {},
		addressBookList: [],
		addressBookSrc: {},
		wordlist: [],
		wordlistSrc: {},
		contactCardList: [],
		contactSrc: {},
		companyList: [],
		contactNoteSrc: {},	//lwb wip
		externalExpertList: [],
		agentListUploadedFile: "",
		avatarUploadedFile: {id: "", value: ""},
		tagList: {},
		groupList: [],
		groupSrc: {},
		folderList: [],
		folderSrc: {},
		connectionSrc: {},
		smppConfigList: [],
		smppConfigSrc: {},
		emailNotificationsSrc: {},
		routingGroupList: [],
		routingGroupSrc: {},
		solidusChannelsList: [],
		smppAreaAccountsList: [],
		smppAreaAccountSrc: {},
		organisationsList:{},
		areaKeywordsList: [],
		autoTagsRulesMap: {},
		areaThemesList: {},
		areaThemeSrc: {},
		areaExtFwdAddrList: {},
		areaReplacementList: {},
		areaAutoFwdExtAddrList: {},
		tickerFilterList: {},
		systemGroupList: [],
		systemGroupSrc: {},
		systemGroupSwitchSrc: {},
		featureSrc: {},
		featureAppliedSrc: {},
		orgConf: {},
		areaConf: {},
		quickSearch:{},
		copyData: {},
	},
	handleSetCopyData: function(copyData) {
        this.setState({ copyData });
    },
	handleGetPriorityList: function(ds){
		this.setState({prioritySrc: ds});
	},
	handleGetCorsConfigUrl: function(list){
		this.setState({corsURLList: list});
	},
	handleGetIpAddressBlacklist: function(list){
		this.setState({ipAddressBlacklist: list});
	},
	handleGetSalutations: function(list){
		this.setState({salutationList: list});
	},
	handleGetSalutationById: function(ds){
		this.setState({salutationSrc: ds});
	},
	handleGetSignatures: function(list){
		this.setState({signatureList: list});
	},
	handleGetSignatureById: function(ds){
		this.setState({signatureSrc: ds});
	},
	handleGetTemplates: function(list){
		this.setState({templateList: list});
	},
	handleGetTemplateById: function(ds){
		this.setState({templateSrc: ds});
	},
	handleGetFileArchives: function(list){
		this.setState({fileArchiveList: list});
	},
	handleGetFileArchiveById: function(ds){
		this.setState({fileArchiveSrc: ds});
	},
	handleGetAddressbook: function(list){
		this.state.addressBookList = list;
	},
	handleGetAddressbookById: function(ds){
		this.state.addressBookSrc = ds;
	},
	handleGetWordlist: function(list){
		this.state.wordlist = list;
	},
	handleGetWordlistById: function(ds){
		this.state.wordlistSrc = ds;
	},
	handleGetContactCardList: function(list){
		this.setState({contactCardList: list});
	},
	handleGetContactById: function(ds){
		this.setState({contactSrc: ds});
	},
	handleGetCompanyList: function(list){
		this.setState({companyList: list});
	},
	//lwb wip
	handleGetCustomerNotesById: function(ds){
		this.setState({contactNoteSrc: ds});
	},
	handleGetExternalExpert: function(list){
		this.setState({externalExpertList: list});
	},
	handleSetFileUpload: function(file){
		this.setState({agentListUploadedFile: file});
	},
	handleSetUploadedAvatar: function(file){
		this.setState({avatarUploadedFile: {id: file.id, value: file.value}});
	},
	handleGetTagList: function(list){
		this.setState({tagList: list});
	},
	handleGetGroups: function(list){
		this.setState({groupList: list});
	},
	handleGetGroupById: function(ds){
		this.setState({groupSrc: ds});
	},
	handleGetFolders: function(list){
		this.setState({folderList: list});
	},
	handleGetFolderById: function(ds){
		this.setState({folderSrc: ds});
	},
	handleGetConnectionById: function(ds){
		this.setState({connectionSrc: ds});
	},
	handleGetSmppConfigs: function(list){
		this.setState({smppConfigList: list});
	},
	handleGetSmppConfigById: function(ds){
		this.setState({smppConfigSrc: ds});
	},
	handleGetEmailNotifications: function(list){
		this.state.emailNotificationsSrc = list;
	},
	handleGetRoutingGroups: function(list){
		this.state.routingGroupList = list;
	},
	handleGetRoutingGroupById: function(item){
		this.state.routingGroupSrc = item;
	},
	handleGetSolidusChannelsList: function(list){
		this.state.solidusChannelsList = list;
	},
	handleGetSolidusChannelById: function(item){
		this.state.solidusChannelSrc = item;
	},
	handleGetOrganisations: function(item){
		this.state.organisationsList = item;
	},
	handleGetAreaSmppAccounts: function(item){
		this.state.smppAreaAccountsList = item;
	},
	handleGetAreaSmppAccountById: function(item){
		this.state.smppAreaAccountSrc = item;
	},
	handleGetAreaKeywords: function(item){
		this.state.areaKeywordsList = item;
	},
	handleGetAreaAutoTagsRules: function(item){
		this.state.autoTagsRulesMap = item;
	},
	handleGetAreaExtFwdAddr: function(item){
		this.state.areaExtFwdAddrList = item;
	},
	handleGetAreaReplacement: function(item){
		this.state.areaReplacementList = item;
	},
	handleGetSystemGroups: function(list){
		this.setState({systemGroupList: list});
	},
	handleGetAreaThemes: function(list){
		this.state.areaThemesList = list;
	},
	handleGetAreaThemeById: function(item){
		this.state.areaThemeSrc = item;
	},
	handleGetAreaAutoFwdExtAddr: function(item){
		this.state.areaAutoFwdExtAddrList = item;
	},
	handleGetTickerFilterList: function(item){
		this.state.tickerFilterList = item;
	},
	handleGetSystemGroupById: function(ds){
		this.setState({systemGroupSrc: ds});
	},
	handleGetSwitchSystemGroups: function(ds){
		this.setState({systemGroupSwitchSrc: ds});
	},
	handleGetFeatures: function(ds){
		this.setState({featureSrc: ds});
	},
	handleGetFeatureApplied: function(ds){
		this.setState({featureAppliedSrc: ds});
	},
	handleGetOrganisationById: function(org){
		this.setState({orgConf: org});
	},
	handleGetAreaById: function(area){
		this.setState({areaConf: area});
	},
	handleGetQuickSearh: function(qs){
		this.setState({quickSearch: qs});
	}
});
