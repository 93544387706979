 var webRoot = window.location.protocol + "//" +window.location.hostname + "/Cention/web/";
import Flux from '../flux';
import ErrandHelper from '../../components/ErrandHelper';

var WorkflowAction =  Flux.createActions({
    getErrands: function (params,onDone) {
        var self = this;
        var autoRefresh = params.errandListAutoRefresh;
        if(!autoRefresh) ErrandHelper.showLoadingWorkflowList("show")
        return function(dispatch){
            $.post(webRoot + 'errand/list',params)
            .done(function (data) {
                var res = {mcam: data, params: params, stillValidRequest: true};
                dispatch(res);
                if(!res.stillValidRequest) {
                    return;
                }
                if(onDone) onDone();
                if(!autoRefresh) ErrandHelper.showLoadingWorkflowList("hide");

            })
            .fail(function (errors) {
                if(onDone) onDone();
                    if(!autoRefresh) ErrandHelper.showLoadingWorkflowList("hide");
            });

        };
    },
    deleteErrands: function(items, onDone){
        return function(dispatch){
            ErrandHelper.deleteErrands(items,function(){
                dispatch(items);
                if (onDone) {
                    onDone();
                }
            }.bind(this));
        }
    },
    closeErrands: function(items, onDone){
        return function(dispatch){
            ErrandHelper.closeErrands(items,function(){
                dispatch(items);
                if (onDone) {
                    onDone();
                }
           }.bind(this));
        }
    },
    clearErrands: function(){
        return true;
    },
    updateContext: function(ctx){
        return ctx;
    },
    updateParams: function(ctx){
        return ctx;
    },
    clearOffset: function(ctx){
        return ctx;
    }
});
export default WorkflowAction;