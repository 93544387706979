import Flux from '../flux';
var webRoot = window.location.protocol + "//" + window.location.hostname + "/Cention/web/";
export default Flux.createActions({
	getPriorityList: function(){
		return function(dispatch){
			$.get( webRoot + 'admin/organisations/priority')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getCorsConfigUrl: function(){
		return function(dispatch){
			$.get( webRoot + 'corsconfig')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveCorsConfigUrl: function(data){
		return $.post( webRoot + "corsconfig", data)
	},
	getIpAddressBlacklist: function(){
		return function(dispatch){
			$.get( webRoot + 'chat/blacklist')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getOrganisationById: function(id){
		return function(dispatch){
			$.get(webRoot + 'admin/org/'+id)
			.then(r =>{
				dispatch(r);
			});
		}
	},
	getAreaById: function(id, oid){
		return function(dispatch){
			$.get(webRoot + 'admin/area/'+id + "/org/" + oid)
			.then(r =>{
				dispatch(r);
			});
		}
	},
	getSalutations: function(pv){
		return function(dispatch){
			$.get( webRoot + 'salutation', {params: JSON.stringify({personal: pv})})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function (errors){});
		}
	},
	getSalutationById: function( id, personal ){
		return function(dispatch){
			$.get( webRoot + "salutation/edit", {params: JSON.stringify({id: parseInt(id), personal: personal})})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveSalutation: function(data){
		return function(dispatch){
			var params = JSON.stringify(data, null, '  ');
			$.post( webRoot + "salutation/save", {params: params})
			.done(function( data ){
				dispatch(data);
			});
		}
	},
	getSignatures: function(pv){
		return function(dispatch){
			$.get( webRoot + 'signature', {params: JSON.stringify({personal: pv})})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function (errors){});
		}
	},
	getSignatureById: function( id, personal ){
		return function(dispatch){
			$.get( webRoot + "signature/edit", {params: JSON.stringify({id: parseInt(id), personal: personal})})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveSignature: function(data){
		return function(dispatch){
			var params = JSON.stringify(data, null, '  ');
			$.post( webRoot + "signature/save", {params: params})
			.done(function( data ){
				dispatch(data);
			});
		}
	},
	getTemplates: function(useFor){
		return function(dispatch){
			console.log('UsedFOR =', useFor);
			$.get( webRoot + 'templates/'+ useFor)
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getTemplateById: function( id ){
		return function(dispatch){
			$.get( webRoot + "template/edit", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveTemplate: function(data){
		return $.post( webRoot + "template/save", data)
	},
	getFileArchives: function(){
		return function(dispatch){
			$.get( webRoot + 'admin/filearchive')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getFileArchiveById: function( id ){
		return function(dispatch){
			$.get( webRoot + "admin/filearchive", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveFileArchive: function(data){
		return $.post( webRoot + "admin/filearchive", data)
	},
	getAddressbook: function( pv, searchText ){
		return function(dispatch){
			$.get( webRoot + 'admin/globalAddressbook/list',{personal: pv, searchText: searchText})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getAddressbookById: function( id ){
		return function(dispatch){
			$.get( webRoot + "admin/globalAddressbook/get", {entry: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveAddressbook: function(data){
		if( data.entry == 0 )
			return $.post( webRoot + "admin/globalAddressbook/save", data)
		else
			return $.post( webRoot + "admin/globalAddressbook/edit", data)
	},
	getWordlist: function(langId){
		return function(dispatch){
			$.get( webRoot + 'wordlist', {langId: langId})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getWordlistById: function( id ){
		return function(dispatch){
			$.get( webRoot + "wordlist/edit", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveWordlist: function(data){
		return $.post( webRoot + "wordlist/save", data)
	},
	getContacts: function(searchText){
		return function(dispatch){
			$.get( webRoot + 'admin/contactcards', {"searchText": searchText})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getContactById: function( id ){
		return function(dispatch){
			$.get(webRoot + "admin/contactcards/" + id)
			.done(function(data){
				dispatch(data);
			});
		}
	},
	getCompanyList: function() {
		return function(dispatch){
			$.get( webRoot + 'admin/companies', {})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	//wip lwb
	getCustomerNotesById: function( groupid ){
		return function(dispatch){
			$.get(webRoot + "errand/notes", {"groupid": groupid})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	getExternalExpert: function(personal, areas){
		return function(dispatch){
			$.get( webRoot + 'externalexpert/address/list', {"personal": personal, "areas": areas})
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	setCopyData(copyData) {
        return function(dispatch) {
            dispatch({
                type: 'SET_COPY_DATA',
                data: copyData,
            });
        };
    },
	setUploadedFile: function(file){
			//this.dispatch(file);
			return file;
	},
	setUploadedAvatar: function(file){
		return file;
	},
	getTagList: function(areas) {
		return function(dispatch){
			$.get( webRoot + 'admin/tag/list')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getGroups: function(){
		return function(dispatch){
			$.get( webRoot + 'group')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getGroupById: function( id ){
		return function(dispatch){
			$.get( webRoot + "group", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveGroup: function(data){
		return $.post( webRoot + "group", data)
	},
	getFolders: function( gid ){
		return function(dispatch){
			$.get( webRoot + 'group/folder',  {gid: parseInt(gid)})
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getFolderById: function( id ){
		return function(dispatch){
			$.get( webRoot + "group/folder", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveFolder: function(data){
		return $.post( webRoot + "group/folder", data)
	},
	getConnectionById: function( id ){
		return function(dispatch){
			$.get( webRoot + "group/connection", id)
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveConnection: function(data){
		return $.post( webRoot + "group/connection", data)
	},
	getSmppConfigs: function(){
		return function(dispatch){
			$.get( webRoot + 'smppconfiguration')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getSmppConfigById: function( id ){
		return function(dispatch){
			$.get( webRoot + "smppconfiguration", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	getEmailNotifications: function(){
		return function(dispatch){
			$.get( webRoot + 'emailnotification')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveEmailNotifications: function(data){
		return $.post( webRoot + "emailnotification", data)
	},
	getRoutinggroups: function(){
		return function(dispatch){
			$.get( webRoot + 'routinggroups')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getRoutinggroupById: function( id ){
		return function(dispatch){
			$.get(webRoot + "routinggroups/"+id)
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveRoutingGroup: function(data){
		return $.post( webRoot + "routinggroups", data)
	},
	getSolidusChannelsList: function(){
		return function(dispatch){
			$.get( webRoot + 'soliduschannels')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getSolidusChannelById: function( id ){
		return function(dispatch){
			$.get(webRoot + "soliduschannels/"+id)
			.done(function(data){
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	removeSolidusChannel: function( action ){
		return $.ajax(action, {
				type: "DELETE"
			})
	},
	saveSolidusChannel: function( data){
		return $.post(webRoot + "soliduschannels", data);
	},
	getOrganisationsList: function(){
		return function(dispatch){
			$.get( webRoot + 'admin/organisations')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveAdminOrganisation: function(orgId, data){
		return $.post( webRoot + "admin/org/" + orgId, data)
	},
	saveOrganisationException: function(orgId, data){
		return $.post(`${webRoot}admin/org/${orgId}/workingtimeexception`, data);
	},
	saveAdminArea: function(areaId, data){
		return $.post( webRoot + "admin/area/" + areaId, data)
	},
	saveAreaException: function(areaId, data){
		return $.post(`${webRoot}admin/area/${areaId}/workingtimeexception`, data);
	},
	getAreaSmppAccounts: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/smppaccounts')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getAreaSmppAccountById: function(areaId, id){
		return function(dispatch){
			$.get(webRoot + "areas/"+areaId + "/smppaccounts/"+id)
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveAreaSmppAccount: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/smppaccounts", data)
	},
	getAreaKeywords: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/keywords')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveOrgExtSysRules: function(orgId, data){
		return $.post( webRoot + "admin/org/" + orgId + "/exturl", data)
	},
	saveAreaExtSysRules: function(areaId, data){
		return $.post( webRoot + "admin/area/" + areaId + "/exturl", data)
	},
	saveAreaKeywords: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/keywords", data)
	},
	getAreaAutoTagRules: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/autotagrules')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveAreaAutoTagRules: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/autotagrules", data)
	},
	getAreaThemes: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/themes')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	getAreaThemeById: function(areaId, id){
		return function(dispatch){
			$.get(webRoot + "areas/"+areaId + "/themes/"+id)
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveAreaThemes: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/themes", data)
	},
	getAreaExtFwdAddr: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/extfwdaddr')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveAreaExtFwdAddr: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/extfwdaddr", data)
	},
	getAreaReplacement: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/replacement')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveAreaReplacement: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/replacement", data)
	},
	getAreaAutoFwdExtAddr: function(id){
		return function(dispatch){
			$.get( webRoot + 'areas/' +id +'/automaticforwardextaddress')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveAreaAutoFwdExtAddr: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/automaticforwardextaddress/newentry", data)
	},
	saveAreaFwdToAddr: function(areaId, data){
		return $.post( webRoot + "areas/" + areaId + "/automaticforwardextaddress", data)
	},
	getSystemGroups: function(){
		return function(dispatch){
			$.get( webRoot + 'systemgroup')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getTickerFilters: function(){
		return function(dispatch){
			$.get( webRoot + 'tickerfilters')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveTickerFilter: function(data){
		return $.post( webRoot + "tickerfilters", data)
	},
	getSystemGroupById: function( id ){
		return function(dispatch){
			$.get( webRoot + "systemgroup", {id: parseInt(id)})
			.done(function(data){
				dispatch(data);
			});
		}
	},
	saveSystemGroup: function(data){
		return $.post( webRoot + "systemgroup", data)
	},
	getSwitchSystemgroups: function(){
		return function(dispatch){
			$.get( webRoot + 'systemgroup/switch')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	switchSystemgroup: function(data){
		return $.post( webRoot + "systemgroup/switch", data)
	},
	getFeatures: function(){
		return function(dispatch){
			$.get( webRoot + 'admin/feature')
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	getFeatureApplied: function(tag){
		return function(dispatch){
			$.get( webRoot + 'admin/feature', {tag: tag})
			.done(function (data) {
				dispatch(data);
			});
		}
	},
	saveFeature: function(data){
		return $.post( webRoot + "admin/feature", data)
	},
	getQuickSearch: function(){
		return function(dispatch){
			$.get( webRoot + 'integration/quicksearch')
			.done(function (data) {
				dispatch(data);
			})
			.fail(function(errors){});
		}
	},
	saveQuickSearch: function(data){
		return $.post( webRoot + "integration/quicksearch", data)
	},
});
