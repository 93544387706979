//define(['react','build/flux/flux', 'build/flux/actions/chatActions'], function (React,Flux,Actions) {
import React from 'react';
import Flux from '../flux';
import Actions from '../actions/chatActions.js';
import addonUpdate from 'react-addons-update';
	function isInt(v) {
		return v === parseInt(v, 10);
	};
	function updateNewLauncher(sessions, feature) {
		var withClient = 0
		, withAgent = 0
		, session
		, i
		, text
		, fgcwa = feature['chat.group-chat-with-agent']
		, fgcwc = feature['chat.group-chat-with-client']
		;
		for(i in sessions) {
			if (!sessions.hasOwnProperty(i)) {
				continue;
			}
			session = sessions[i].session;
			if (!session) {
				// this is only chat tags, not the full session.
				continue;
			}
			if (session.errandId && session.client) {
				withClient++;
			} else {
				withAgent++;
			}
		}
		if (window.parent.$('#globalChatLauncherNew')) {
			// If you change this text, please change the one in header.js as well
			if (withAgent == 0 && withClient == 0) {
				text = I('0 incoming chat requests');
			} else {
				// There are four possible combos
				if        ( fgcwa &&  fgcwc) {
					text = I('{WITH_AGENT} agent chat(s), {WITH_CLIENT} client chat(s).')
						.replace("{WITH_AGENT}", withAgent)
						.replace("{WITH_CLIENT}", withClient)
				} else if ( fgcwa && !fgcwc) {
					text = I('{WITH_AGENT} agent chat(s), {WITH_CLIENT} client chat(s).')
						.replace("{WITH_AGENT}", withAgent)
						.replace("{WITH_CLIENT}", withClient)
				} else if (!fgcwa &&  fgcwc) {
					text = I('{WITH_CLIENT} incoming chat requests(s).')
						.replace("{WITH_CLIENT}", withClient)
				} else if (!fgcwa && !fgcwc) {
					text = I('{WITH_CLIENT} incoming chat request(s).')
						.replace("{WITH_CLIENT}", withClient)
				} else {
					// We should not reach here
					text = "Internal group chat error. Please report this problem.";
				}
			}
			window.parent.$('#globalChatLauncherNew').text(text);
		}
	};
export default Flux.createStore({
		displayName: 'ChatStore',
		bindListeners: {
			handleShowSystemErrorMessage: Actions.SHOW_SYSTEM_ERROR_MESSAGE,
			handleNewSessionReceived: Actions.NEW_SESSION_RECEIVED,
			handleOnMessageAcked: Actions.ON_MESSAGE_ACKED,
			handleOnPreview: Actions.ON_PREVIEW,
			handleUpdateClientStatus: Actions.UPDATE_CLIENT_STATUS,
			handleOnDeadSession: Actions.ON_DEAD_SESSION,
			handleOnQueueSend: Actions.ON_QUEUE_SEND,
			handleDoSend: Actions.DO_SEND,
			handleUpdateMessage: Actions.UPDATE_MESSAGE,
			handleOnActivate: Actions.ON_ACTIVATE,
			handleOnFinishSession: Actions.ON_FINISH_SESSION,
			handleOnUnsentMessages: Actions.ON_UNSENT_MESSAGES,
			handleOnTags: Actions.ON_TAGS,
			handleOnClientPaths: Actions.ON_CLIENT_PATHS,
			handleOnUpdateTags: Actions.ON_UPDATE_TAGS,
			handleOnAgentPresence: Actions.ON_AGENT_PRESENCE,
			handleOnConnectStatus: Actions.ON_CONNECT_STATUS,
			handleOnAcceptChat: Actions.ON_ACCEPT_CHAT,
			handleAcquireErrand: Actions.ACQUIRE_ERRAND,
			handleAssociateErrand: Actions.ON_ASSOCIATE_ERRAND,
			handleOnSessionInit: Actions.ON_SESSION_INIT,
		},
		state: {
			chatSessions: {},
			unsentMessageId: 0,
			agentPresence: {},
			chatAreas: {},
			feature: {},
			acceptChat: true,
			languageSrc: {}
		},
		getLastPreviewMsg: function(chats){
			for(var i=chats.length - 1;i>=0;i--){
				if(chats[i].preview && chats[i].fromClient){
					return {
						index:i,
						msg: chats[i]
					}
				}
			}
			return {
				index:-1,
				msg:""
			}
		},
		removeLastPreviewMsg: function(chats){
			var lastMessage = this.getLastPreviewMsg(chats);
			if(lastMessage.index >= 0) {
				chats.splice(lastMessage.index, 1);
			}
		},
		handleShowSystemErrorMessage: function(o){
			console.log("handleShowSystemErrorMessage:",o);
			if(this.state.chatSessions[o.sessionId]) {
				this.state.chatSessions[o.sessionId].notification = {header:"",text:o.msg}
			}
		},
		handleNewSessionReceived: function(msg){
			var sessionId = msg.sessionId;
			var newChatSessions = $.extend(true, {},this.state.chatSessions);
			var newSession = {};
			var mcount;
			if(typeof msg.agentIds === 'undefined'){
				msg.agentIds = [];
			}

			if(newChatSessions[sessionId]) {
				if(newChatSessions[sessionId].session) {
					this.removeLastPreviewMsg(newChatSessions[sessionId].session.chat);
					newSession = $.extend(true, {},newChatSessions[sessionId].session);
					mcount = newChatSessions[sessionId].mcount;
					var chats = newSession.chat;
					var newChats = msg.chat;
					newSession.agentIds = msg.agentIds;
					newSession.invitedAgentIds = msg.invitedAgentIds;
					newSession.isInvited = msg.isInvited;
					newSession.area = msg.area;
					newSession.clientStatus = msg.clientStatus;
					newSession.ipaddress = msg.ipaddress;
					newSession.externalData = msg.externalData;
					newSession.newMessage = msg.hasUnacked;
					for (let j=0; j<newChats.length; j++) {
						if (mcount[newChats[j].id]) {
							continue;
						}
						chats.push(newChats[j]);
					}
				} else {
					newSession = msg;
					newSession.mcount = {};
					newSession.unsentMessagesArray = [];
				}
				newSession.isDirty = msg.hasUnacked;
				newSession.newMessage = msg.hasUnacked;
			} else {
				newSession = msg;
				newSession.mcount = {};
				newSession.unsentMessagesArray = [];
				newSession.isDirty = msg.hasUnacked;
				newSession.newMessage = msg.hasUnacked;
				newChatSessions[sessionId] = {};
			}

			if (msg.errandId > 0) {
				// mark previously not marked as "read by
				// client" messages from agent as "read".
				// These are messages that was sent by agent
				// but not received by the client.
				chats = newSession.chat;
				for(let j=0;j<chats.length;j++) {
					if (chats[j].aid>0) {
						// message from agent
						if (!chats[j].read) {
							// unread by client, mark is as read.
							chats[j].read = chats[j].id <= msg.cmaxId;
						}
					}
				}
			}

			newSession.dead = msg.dead;
			newSession.ownerId = msg.ownerId;
			var mergedSession = addonUpdate(newChatSessions[sessionId],{
				$merge: {session:newSession}
			});
			newChatSessions[sessionId] = mergedSession;
			newChatSessions[sessionId].notification = {header:"",text:""};
			this.setState({
				connectStatus: "connected",
				chatSessions: newChatSessions
			});
			for(var i=0;i<msg.chat.length;i++){
				msg.chat[i].read = msg.chat[i].id <= msg.cmaxId;
				this.maybeSetMcount(msg.sessionId, msg.chat[i].id);
			}
			updateNewLauncher(newChatSessions, this.state.feature);
			if(msg.autoDisplayIfActive) {
				msg.autoDisplayIfActive(newSession.dead,newSession.isDirty, newSession.newMessage);
			}
			msg.ack(this.getMcount(msg.sessionId), msg.chat.length);
		},
		handleOnMessageAcked: function(msg) {
			var newChatSessions = $.extend(true, {},this.state.chatSessions)
			, i, j, sessionId = msg.sessionId
			, sess = $.extend(true, {}, this.state.chatSessions[sessionId])
			, state
			, changed = false
			;
			if (!sess||!sess.session) return;
			for (j=0;j<msg.ids.length;j++) {
				for(i=sess.session.chat.length-1;i>=0;i--) {
					if (sess.session.chat[i].id != msg.ids[j]) {
						continue;
					}
					sess.session.chat[i].read = true;
					changed = true;
					break;
				}
			}
			if (changed) {
				newChatSessions[msg.sessionId] = sess;
				this.setState({chatSessions:newChatSessions});
			}
		},
		handleUpdateMessage:function(o){
			var cs = this.state.chatSessions[o.sessionId]
			, sent
			, rendered
			;

			if (!cs) { return; }

			if (o.okToRemove) {
				sent = cs.session.unsentMessagesArray.shift();
			}

			if (sent) {
				rendered = cs.session.chat[sent.chatIndex]
				rendered.id = o.msg.id;
				rendered.preview = false;
				rendered.sentHuman = o.msg.sentHuman;
				rendered.error = o.errorNote;
				this.maybeSetMcount(o.sessionId, o.msg.id);
			}
		},
		handleOnQueueSend: function(msg){
			var doCallback = function(o){
				setTimeout(function(){
					if(msg.callback) msg.callback(o);
				});
			};
			if (this.state.chatSessions[msg.sessionId]) {
				var lastMessage = {
					fromClient:false,
					id:this.getNewMessageId(),
					aid:initialChatData.agentID,
					agent:initialChatData.agentName,
					read:false,
					sent:0,
					sentHuman:"",
					text:msg.preview,
					preview:true,
					chatIndex: this.state.chatSessions[msg.sessionId].session.chat.length,
				};
				this.state.chatSessions[msg.sessionId].session.chat.push(lastMessage);
				this.state.chatSessions[msg.sessionId].session.unsentMessagesArray.push(lastMessage);
				if (this.state.chatSessions[msg.sessionId].session.unsentMessagesArray.length == 1) {
					doCallback({
						um: this.state.chatSessions[msg.sessionId].session.unsentMessagesArray,
						sessionId: msg.sessionId,
						errandId: msg.errandId,
						mCount: this.getMcount(msg.sessionId)
					});
				}
			}
		},
		getUnsentMsgs: function(sessionId){
			var cs =  this.state.chatSessions[sessionId]
			, i
			, unsentMsgs = []
			;
			if(cs) {
				return cs.session.unsentMessagesArray;
			}
			return [];
		},
		findUnsentMsg: function(sessionId,umid){
			if(this.state.chatSessions[sessionId]) {
				var chats = this.state.chatSessions[sessionId].session.chat;
				for (var i=0; i<chats.length; i++) {
					if (chats[i].preview == true && !chats[i].fromClient && chats[i].id == umid ) {
						return {index:i,msg:chats[i]};
					}
				}
			}
			return {index:-1,msg:""};
		},
		handleOnUnsentMessages: function(msg){
			var doCallback = function(c){
				setTimeout(function(){
					if(msg.callback) msg.callback(c);
				});
			};
			if(Object.keys(this.state.chatSessions).length > 0){
				$.each(this.state.chatSessions, function(index, o) {
					if (!o.session) {
						// We have not yet received the full chat session (most
						// likely this is immediately after a browser reload so
						// there are no unsent messages yet)
						return;
					}
					var unsent = this.getUnsentMsgs(o.session.sessionId);
					if(unsent.length > 0){
						doCallback({
							um: unsent,
							sessionId: o.session.sessionId,
							errandId: o.session.errandId,
							mCount: this.getMcount(o.session.sessionId)
						});
					}
				}.bind(this));
			}
		},
		// Mcount returns the number of messages that we have received so far
		// for the given chat session
		getMcount: function(sessionId) {
			var mcount;
			if (!this.state.chatSessions[sessionId]) {
				return 0;
			}

			mcount = this.state.chatSessions[sessionId].mcount;
			if (!mcount) {
				this.state.chatSessions[sessionId].mcount = {};
				mcount = this.state.chatSessions[sessionId].mcount;
			}
			return Object.keys(mcount).length;
		},
		maybeSetMcount: function(sessionId,messageId){
			var mcount;
			if (!isInt(messageId)) {
				return;
			}
			mcount = this.state.chatSessions[sessionId].mcount;
			if (!mcount) {
				this.state.chatSessions[sessionId].mcount = {};
				mcount = this.state.chatSessions[sessionId].mcount;
			}
			if (!mcount[messageId]) {
				mcount[messageId] = true;
			}
		},
		getNewMessageId: function() {
			var timestamp = (new Date()).getTime()
			, agentId = initialChatData.agentID
			;

			if (agentId <= 0) {
				return "INVALID";
			}
			this.state.unsentMessageId++;
			return 'a-' + agentId + '-' + timestamp + '-' + this.state.unsentMessageId;
		},
		handleDoSend: function(socketCallBack){
			if(socketCallBack){
				socketCallBack([]);
			}
		},
		handleOnPreview: function(msg){
			if (this.state.chatSessions[msg.sessionId] && this.state.chatSessions[msg.sessionId].session) {
				var lastMessage = this.getLastPreviewMsg(this.state.chatSessions[msg.sessionId].session.chat);
				if(lastMessage.index >= 0){
					this.state.chatSessions[msg.sessionId].session.chat[lastMessage.index].text = msg.preview;
				} else {
					if(msg.preview != ""){
						this.state.chatSessions[msg.sessionId].session.chat.push({
							fromClient:true,
							id:0,
							read:false,
							sent:0,
							sentHuman:"",
							text:msg.preview,
							preview:true
						});
					}
				}
			}
		},
		handleUpdateClientStatus: function(o){
			if (this.state.chatSessions[o.id] && !this.state.chatSessions[o.id].session.dead) {
				this.state.chatSessions[o.id].session.clientStatus = o.status;
			}
		},
		handleOnDeadSession: function(msg){
			var text;
			if (this.state.chatSessions[msg.sessionId] && !this.state.chatSessions[msg.sessionId].session.dead) {
				this.state.chatSessions[msg.sessionId].session.dead = true;
				this.state.chatSessions[msg.sessionId].session.clientStatus = 'Offline';
			}
		},
		handleOnActivate: function(id){
			if (this.state.chatSessions[id]) {
				this.state.chatSessions[id].session.isDirty = false;
			}
		},
		handleOnFinishSession: function(o){
			var notice;
			if (this.state.chatSessions[o.sessionId]) {
				if (o.error) {
					alert(I("There was an error closing the chat: {ERROR}").replace('{ERROR}', o.error));
					return;
				}
				if (o.isOwner && this.state.chatSessions[o.sessionId]){
					var CloseErrands = function(errands,tags){
						$.post( webserverRoot + 'errand/closeErrands', {
							list: errands,
							update_tags: tags
						});
					};
					var tags = [];
					if (this.state.chatSessions[o.sessionId].tags) {
						tags = this.state.chatSessions[o.sessionId].tags
					}
					tags = tags.filter(function(item){
						return item.selected == true;
					}).map(function(item){
						return item.id;
					});
					var associatedErrands = "";
					if(this.state.chatSessions[o.sessionId].associatedErrands && this.state.chatSessions[o.sessionId].associatedErrands.length > 0){
						associatedErrands = this.state.chatSessions[o.sessionId].associatedErrands.join(',');
						CloseErrands(associatedErrands,tags.join(','));
					}
				}
				if (o.isOwner || o.guestLeaving || o.isStale) {
					delete(this.state.chatSessions[o.sessionId]);
					updateNewLauncher(this.state.chatSessions, this.state.feature);
				} else {
					if (o.expired) {
						notice = I("This chat has expired.");
					} else if (o.closedBy) {
						notice = I("{NAME} has closed this chat")
							.replace("{NAME}", o.closedBy);
					} else if (o.needTag) {
						notice = I("Please tag this chat to close it");
					} else {
						notice = I("This chat has ended.");
					}
					if (!o.needTag) {
						this.state.chatSessions[o.sessionId].session.dead = true;
					}
					this.state.chatSessions[o.sessionId].notification = {
						header: "",
						text: notice,
					};
				}
			}
		},
		handleOnTags: function(o){
			var sortedTags = o.tags.sort(function(a,b){
				return(a.value.localeCompare(b.value))
			});
			var sessionId = o.sessionId;
			var newChatSessions = $.extend(true, {},this.state.chatSessions);
			if(!newChatSessions[sessionId]) {
				newChatSessions[sessionId] = {};
			}
			var mergedSession = addonUpdate(newChatSessions[sessionId],{
				$merge: {tags:sortedTags, errandTags: o.errandTags}
			});
			newChatSessions[sessionId] = mergedSession;
			this.setState({chatSessions:newChatSessions});
		},
		handleOnUpdateTags: function(o){
			var sessionId = o.sessionId;
			var newChatSessions = $.extend(true, {},this.state.chatSessions);
			var updated = false;
			if(newChatSessions[sessionId] && newChatSessions[sessionId].tags) {
				for(var j=0;j<newChatSessions[sessionId].tags.length;j++){
					if(newChatSessions[sessionId].tags[j].selected == true){
						newChatSessions[sessionId].tags[j].selected = false;
						updated = true;
					}
					for(var i=0;i<o.tags.length;i++){
						if(newChatSessions[sessionId].tags[j].id == o.tags[i]){
							newChatSessions[sessionId].tags[j].selected = true;
							updated = true;
						}
					}
				}
				newChatSessions[sessionId].selectedTags = o.selectedTags;
			}
			if(updated){
				this.setState({chatSessions:newChatSessions});
			}
		},
		handleOnClientPaths: function(o){
			var prevTime = Math.round(new Date().getTime()/1000);
			o.totalTime = 0;
			for(var i = 0;i<o.paths.length;i++){
				var lapsed = prevTime - o.paths[i].time;
				o.paths[i].lapsed = this.formatRoundTime(lapsed);
				o.totalTime += lapsed;
				prevTime = o.paths[i].time;
			}
			o.totalTime = this.formatTimeHMS(o.totalTime);
			var sessionId = o.sessionId;
			var newChatSessions = $.extend(true, {},this.state.chatSessions);
			if(!newChatSessions[sessionId]) {
				newChatSessions[sessionId] = {session:{}};
			}
			var mergedSession = addonUpdate(newChatSessions[sessionId],{
				session:{$merge: {clientPaths:o}}
			});
			newChatSessions[sessionId] = mergedSession;
			this.setState({chatSessions:newChatSessions});
		},
		formatRoundTime: function(s){
			var r = Math.floor(s/3600);
			if(r<=0){
				r = Math.floor(s/60);
				if(r<=0){
					return s + ' s';
				}
				return r + ' m';
			}
			return r + ' h';
		},
		formatTimeHMS: function(s){
			var h = Math.floor(s/3600);
			var r = s%3600;
			if(r>0){
				var m = Math.floor(r/60);
				s = r%60;
			}
			return {hour:h,min:m,sec:s};
		},
		handleOnAgentPresence: function(o){
			this.updateAgentPresence(o);

			if (o.acceptChat == false || o.online ==false) {
				// Don't need to update area.
				return;
			}

			// There might be new areas added that we didn't know of.
			var maybeNewAreas = {};
			o.areas.map(function(area){ maybeNewAreas[area.id] = area; });
			var mergedAreas = addonUpdate(this.state.chatAreas, {
				$merge: maybeNewAreas
			});
			this.setState({chatAreas: mergedAreas});
		},
		updateAgentPresence: function(agent) {
			var merge = {};
			merge[agent.id] = {
				acceptChat: agent.acceptChat,
				chatName: agent.chatName,
				id: agent.id,
				online: agent.online,
				userName: agent.userName,
			};
			var mergedAgents = addonUpdate(this.state.agentPresence, {
				$merge: merge
			});
			this.setState({agentPresence: mergedAgents});
		},
		handleOnConnectStatus: function(o) {
			this.setState(o);
		},
		handleOnAcceptChat: function(o) {
			this.setState({acceptChat: o});
			var changeToggle = window.parent.$('#globalChatLauncherToggle');
			if (changeToggle){
				changeToggle.trigger("chatToggle", [o]);
			}
		},
		handleAssociateErrand: function(o){
			if (this.state.chatSessions[o.sessionId]) {
				this.state.chatSessions[o.sessionId].associatedErrands = o.associatedErrands;
			}
		},
		handleAcquireErrand: function(o){
			if (this.state.chatSessions[o.sessionId]) {
				this.state.chatSessions[o.sessionId].acquiredRelatedErrands.acquired.push(o.errand);
			}
		},
		handleOnSessionInit: function(o){
			var chat = this.state.chatSessions[o.sessionId];
			if (chat) {
				chat.acquiredRelatedErrands = o.acquiredRelatedErrands;
				chat.chatNotesCounter = o.chatNotesCounter;
				chat.customerNotesCounter = o.customerNotesCounter;
				chat.associatedErrands = [];
				chat.sessionSecret = o.sessionSecret;
			}
		}
	});
