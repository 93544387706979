import Flux from '../flux';
var webRoot = window.location.protocol + "//" + window.location.hostname + "/Cention/web/";
export default Flux.createActions({
		showSystemErrorMessage: function (o) {
			return function(dispatch){
				dispatch(o);
			}
		},
		onMessageAcked: function(o) {
			return function(dispatch){
				dispatch(o);
			}
		},
		onPreview: function(o){
			return function(dispatch){
				dispatch(o);
			}
		},
		updateClientStatus: function(o){
			return function(dispatch){
				dispatch(o);
			}
		},
		onDeadSession: function (o){
			return function(dispatch){
				dispatch(o);
			}
		},
		newSessionReceived: function(o) { return function(dispatch){dispatch(o);} },
		onQueueSend: function(o) { return function(dispatch){dispatch(o);} },
		doSend: function(o) { return function(dispatch){dispatch(o);} },
		updateMessage: function(o) { return function(dispatch){dispatch(o);} },
		onActivate: function(o) { return function(dispatch){dispatch(o);} },
		onFinishSession: function(o) { return function(dispatch){dispatch(o);} },
		onUnsentMessages: function(o) { return function(dispatch){dispatch(o);} },
		onTags: function(o) { return function(dispatch){dispatch(o);} },
		onClientPaths: function(o) { return function(dispatch){dispatch(o);} },
		onUpdateTags: function(o) { return function(dispatch){dispatch(o);} },
		onAgentPresence: function(o) { return function(dispatch){dispatch(o);} },
		onConnectStatus: function(o) { return function(dispatch){dispatch(o);} },
		onAcceptChat: function(o) { return function(dispatch){dispatch(o);} },
		acquireErrand: function(o) { return function(dispatch){dispatch(o);} },
		onAssociateErrand: function(o) { return function(dispatch){dispatch(o);} },
		onSessionInit: function(o){
			var args = o.args;
			return function(dispatch){
				$.ajax({
					url: "/socket/agent.api/sessiondata",
					type: "POST",
					cache: false,
					data: args
				}).done(function(resp) {
					if(resp.error){
						o.onFail && o.onFail(resp.error);
					} else {
						dispatch(resp);
						o.onDone && o.onDone(resp);
					}
				}.bind(this)).fail(function(e) {
					o.onFail && o.onFail(e);
				});
			}
		}
	});
