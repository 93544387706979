import Flux from '../flux';
import ErrandHelper from '../../components/ErrandHelper';
import Actions from '../actions/workflowActions.js';

export default Flux.createStore({
	displayName: 'ErrandStore',
	bindListeners: {
		handleGetErrands: Actions.GET_ERRANDS,
		handleClearErrands: Actions.CLEAR_ERRANDS,
		handleDeleteErrands: Actions.DELETE_ERRANDS,
		handleCloseErrands: Actions.CLOSE_ERRANDS,
		handleUpdateContext: Actions.UPDATE_CONTEXT,
		handleSortParams: Actions.UPDATE_PARAMS,
					handleClearOffset: Actions.CLEAR_OFFSET
	},
	state: {
		errandLists: [],
		selectedErrandList: [],
		errandCount: 0,
		upperCount: 0,
		totalErrandCount: 0,
		totalUpperCount: 0,
		agentStats: false,
		totalGroupedErrands: 0,
		ctx: {
			acquireErrand:{
				owner:{
					id: 0
				}
			},
			current: localStorage.getItem("currentErrandContext"),
			previous: localStorage.getItem("currentErrandContext"),
			fromAnswer: "",
			sortByExpired: 'no',
			sortByExpiredNewErrand: 'no',
			sortByWarning: 'no',
			sortByWarningNewErrand: 'no',
			sortByReplies: 'no',
			sortByRepliesNewErrand: 'no',
			sortByPriority: 'no',
			sortByPriorityNewErrand: 'no',
			listSize: 17,
			offset: 0,
			activeAreas: "",
			newActiveAreas: "",
			myActiveAreas: "",
			errandRawSrcList: {},
			sortAttribute: 'date',
			sortDirection: 'asc'
		}
	},
	handleGetErrands: function (data) {
		var res = {rqFromSameCtx: false};
		var errandList = this.getErrandList(data.mcam, res);
		if(!res.rqFromSameCtx) {
			data.stillValidRequest = false;
			console.info("request come from different context. please switch or wait patiently.");
			return;
		}
		this.state.errandLists = errandList;
		this.state.errandCount = ErrandHelper.getErrandCount( data.mcam, data.params.source );
		this.state.totalErrandCount = this.getTotalErrandCount( data.mcam, data.params.source );
		this.state.totalUpperCount = this.getTotalUpperCount( data.mcam, data.params.source );
		this.state.agentStats = this.getAgentStats( data.mcam );
		this.state.totalGroupedErrands = this.getTotalGroupedErrands( data.mcam );
		//Setting up count data for menu
		ErrandHelper.updateErrandCounter(data.mcam);
		if(data.params && data.params.callback){data.params.callback();}
		var currentOffset = this.state.ctx.offset;
		if(data) {
			data.mcam.mcam.channels.forEach(function(d){
				if(typeof d.content != undefined && d.id == "fetchErrandList"){
					var contents = JSON.parse(d.content);
					currentOffset = contents.base.offset;
				}
			}.bind(this));
		}
		this.state.ctx.offset = currentOffset;
	},
	handleClearErrands: function () {
		this.state.errandLists = [];
		this.state.selectedErrandList = [];
		this.state.errandCount = 0;
		this.state.totalErrandCount = 0;
		this.state.upperCount = 0;
		this.state.totalUpperCount = 0;
	},
	handleDeleteErrands: function(items){
		this.removeItemsFromList(items.errandListToDelete);
	},
	handleCloseErrands: function(items){
		this.removeItemsFromList(items.errandListToClose);
	},
	handleUpdateContext: function(ctx){
		if(ctx ==  ErrandHelper.ERRAND_SRC_CONTEXT_ANSWER){
			this.state.ctx.fromAnswer = ctx;
		} else {
			this.state.ctx.fromAnswer = "";
			this.state.ctx.previous = this.state.ctx.current;
			this.state.ctx.current = ctx;
			localStorage.setItem("currentErrandContext", ctx);
		}
	},
	handleSortParams: function( ctx ){
		this.state.ctx.sortByExpired = ctx.sortByExpired;
		this.state.ctx.sortByExpiredNewErrand = ctx.sortByExpiredNewErrand;
		this.state.ctx.sortByWarning = ctx.sortByWarning;
		this.state.ctx.sortByWarningNewErrand = ctx.sortByWarningNewErrand;
		this.state.ctx.sortByReplies = ctx.sortByReplies;
		this.state.ctx.sortByRepliesNewErrand = ctx.sortByRepliesNewErrand;
		this.state.ctx.sortByPriority = ctx.sortByPriority;
		this.state.ctx.sortByPriorityNewErrand = ctx.sortByPriorityNewErrand;
		this.state.ctx.listSize = ctx.listSize;
		this.state.ctx.offset = ctx.offset;
		this.state.ctx.sortAttribute = ctx.sortAttribute;
		this.state.ctx.sortDirection = ctx.sortDirection;
		if(ctx.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_NEW){
			this.state.ctx.newActiveAreas = ctx.activeAreas;
		} else {
			this.state.ctx.myActiveAreas = ctx.activeAreas;
		}
	},
	handleClearOffset: function(){
		this.state.ctx.offset = 0;
	},
	removeItemsFromList: function(list){
		list.forEach(function(item){
			var index=-1;
			for (var i = 0; i < this.state.errandLists.length; i++) {
				if (this.state.errandLists[i].id === item) {
					index = i;
					break;
				}
			}
			if (index > -1) {
				this.state.errandLists.splice(index, 1);
			}        		
		}.bind(this));
	},
	getTotalErrandCount: function( datas, hashpath ){
		var totalErrandCount = 0;
		var errandCount = 0;
		var source = (hashpath == ErrandHelper.ERRAND_SRC_CONTEXT_NEW ? "new" : "my" );
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.id == "fetchErrandList"){
				var contents = JSON.parse(d.content);
				totalErrandCount = contents.statistics.max;
			}
		}.bind(this));
		return totalErrandCount;
	},
	getTotalUpperCount: function( datas, hashpath ){
		var errandCount = 0;
		var totalUpperCount = 0;
		var source = (hashpath == ErrandHelper.ERRAND_SRC_CONTEXT_NEW ? "new" : "my" );
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.id == "fetchErrandList"){
				var contents = JSON.parse(d.content);
				totalUpperCount = contents.statistics.upper;
			}
		}.bind(this));
		return totalUpperCount;
	},
	getAgentStats: function(datas) {
		var stats = false;
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.content != ""){
				var contents = JSON.parse(d.content);
				if( contents.agentStatistics != undefined){
					stats = contents.agentStatistics;
				}
			}
		}.bind(this));
		return stats;
	},
	getTotalGroupedErrands: function(datas) {
		var count = 0;
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.content != ""){
				var contents = JSON.parse(d.content);
				if( contents.totalGroupErrands != undefined){
					count = contents.totalGroupErrands;
				}
			}
		}.bind(this));
		return count;
	},
	getErrandList: function(datas, res) {
		var populateList = [];
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.content != ""){
				var contents = JSON.parse(d.content);
				if(contents.base &&
					contents.base.source === this.state.ctx.current) {
					res.rqFromSameCtx = true;
				} else {
					return;
				}
				if( contents.errands != undefined){
					this.state.ctx.errandRawSrcList = contents.errands;
					var oderedResult = [];
					contents.errands.order.forEach(function( key ){
						var found = false;
						contents.errands.list.filter(function( item ) {
							if(!found && item.id == key) {
								oderedResult.push(item);
								found = true;
								return false;
							} else 
								return true;
						});
					}.bind(this));
					oderedResult.forEach(function(errand){
						var errandList = {};
						var areaName = errand.data.areaName;
						var subject = errand.data.iconicSubject;
						const areaNameMaxLength = 20;
						const subjectMaxLength = 50;						

						if( $( window ).width() < 1024){
							if(areaName.length > areaNameMaxLength)
								areaName = areaName.substr(0, areaNameMaxLength) + '..';
						}
						if( /\>/.test(subject) ){
							var context = subject.split(/\>/);
							var newSubject = context[0] + ">";
							if( context.length > 1 ){
								if(context[1].length > subjectMaxLength){
									newSubject += context[1].substr(0, subjectMaxLength) + '...';
									subject = newSubject;
								}
							}
						}else{
							if(subject.length > 50){
								subject = subject.substr(0, subjectMaxLength) + '...';
							}
						}
						errandList.id = errand.data.id;
						errandList.handleId = errand.data.id;
						if(errand.hasChild != true && errand.groupWith != 0){
							errandList.handleId = errand.groupWith;
						}
						errandList.displayId = errand.data.displayId;
						errandList.status = errand.data.status;
						errandList.subject = subject;
						errandList.body = errand.data.body;
						errandList.agent = errand.data.agent;
						errandList.organisationName = errand.data.organisationName;
						errandList.areaName = areaName;
						errandList.areaId = errand.data.area;
						errandList.from = errand.data.from;
						errandList.date = errand.data.date;
						errandList.service = errand.data.service;
						errandList.serviceName = errand.data.serviceName;
						errandList.fromAddress = errand.data.fromAddress;
						errandList.fromName = errand.data.fromName;
						errandList.donedate =  errand.data.donedate;
						errandList.answeredDate = errand.data.answeredDate;
						errandList.attachments = errand.data.attachments;
						errandList.priority = errand.data.priority;
						errandList.style = errand.style;
						errandList.groupWith = errand.groupWith;
						errandList.threadId = errand.threadId;
						errandList.hasChild = errand.hasChild
						populateList.push(errandList);
					});
				}
			}
		}.bind(this));
		
		return populateList;
	}
});
// });